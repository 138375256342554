import React from 'react'
import Aktivitet from '../components/Layout/Aktivitet/Aktivitet'

const Aktiviteter = () => {
    return (
        <div className="container my-5">
            <h3>Møllerup Gods tilbyder en perlerække af muligheder</h3>
            <p>Møllerup Gods og Hubertus Kroen er ikke alene kulturhistoriske perler, vi er også en virksomhed med højt til loftet, masser af muligheder og god plads til aktiviteter på 425 hektar - for private og erhverv</p>
            <Aktivitet />
        </div>
    )
}

export default Aktiviteter
